import { uniqueSelector } from "./uniqueSelector";
import { redirect } from "../reloadRedirect";
import { docReady } from "../docReady";
import { closeModal } from "../controls/modal";
import { reloadPage } from "../reloadRedirect";
import { clearValidation } from "../validation/index";


$(function () {
    // these handlers must be included so they are added automatically when using the ajax library
    $(document).ajaxSend(ajaxSend);
    $(document).ajaxError(ajaxError);
});


/**
 * Called for every $.ajax request.  Add a custom "sender" property to the
 * ajax options to have the framework provide additional common handling
 * to the request.
 * @param {Event} event
 * @param {JQueryXHR} xhr
 * @param {object} options Ajax request options after prep by $.ajax
 * @example
 * $(document).ajaxSend(ajaxSend);
 */
function ajaxSend(event, xhr, options) {
  if (!Object.prototype.hasOwnProperty.call(options, "cache")) options.cache = false;
  if (!options.statusCode) options.statusCode = {};
  if (!Object.prototype.hasOwnProperty.call(options.statusCode, 401)) options.statusCode[401] = function () { redirect('/account/login'); };
  if (!Object.prototype.hasOwnProperty.call(options.statusCode, 403)) options.statusCode[403] = function () { redirect('/account/login'); };
  if (!Object.prototype.hasOwnProperty.call(options.statusCode, 404)) options.statusCode[404] = function () { document.write(`Error 404: Page not found.\n\n`); };

  if (options.source) {
      addXhrHandler(xhr, options);
  }
}


/**
 * The ajaxSend 403 wasn't catching every possible 403 response
 * so this handler for ajaxError seems to fill in the gaps.
 * @param {Event} event
 * @param {JQueryXHR} xhr
 */
function ajaxError(event, xhr) {
    if (xhr.status === 401 || xhr.status === 403) {
        redirect('/account/login');
    }
}


/**
 * Extracts common data properties from the element that initiated the ajax call 
 * and inserts a custom "done" function ahead of the return to the caller.
 * @param {JQueryXHR} xhr
 * @param {object} ajaxOptions
 */
function addXhrHandler(xhr, ajaxOptions) {
    const sourceSelector = ajaxOptions.source;
    const $sourceElement = ajaxOptions.source instanceof (jQuery) ? sourceSelector : $(sourceSelector); //$(sourceSelector);

    let targetSelector =
        $sourceElement.data("target") ?
            $sourceElement.data("target") :
            $sourceElement.closest(".ajax-replace").get(0) ?
                uniqueSelector($sourceElement.closest(".ajax-replace")) :
                null;

    //let $target = $(targetSelector);
    let $target = targetSelector ? $(targetSelector) : $sourceElement;
    let $modelErrorTarget = $sourceElement.data("model-error-target")
        ? $($sourceElement.data("model-error-target"))
        : $target;

    let replace = $sourceElement.data("replace") ? $sourceElement.data("replace") : $target.data("replace");

    // selectors only
    const handlerConfiguration = {
        target: $target, //targetSelector,
        replace,
        dismiss: $sourceElement.data("dismiss-modal"),
        scrollTo: $sourceElement.data("scrollto"),
        fadeIn: $sourceElement.data("fadein"),
        modelError: {
            target: $modelErrorTarget, //$sourceElement.data("model-error-target") ? $sourceElement.data("model-error-target") : targetSelector,
            scrollTo: $sourceElement.data("model-error-scrollto"),
            replace
        }
    }
    xhr.done(function (data) {
        xhrDone(handlerConfiguration, data);
    });
}


/**
* Utlity function for xhrDone.
* @param {object} config
* @param {string} partial
*/
function loadPartial(config, partial) {
    //let $target = $(config.target);
    let $target = config.target;
    if (config.replace) {
        $target.replaceWith(partial);
    }
    else {
        if ($target.hasClass("ajax-replace")) {
            $target.replaceWith(partial);
        }
        else {
            $target.html(partial);
        }
    }

    if (config.fadeIn) {
        $(config.fadeIn).fadeIn(500);
    }
    if (config.scrollTo) {
        $("html, body").animate({
            scrollTop: $(config.scrollTo).offset().top
        }, 1000);
    }
}


/**
* Common "done" handler for XHR requests.  This will execute ahead of any
* done function provided by a call to $.ajax if the call provides a "source"
* parameter.
* @param {object} config
* @param {any} data
*/
function xhrDone(config, data) {
    if (data.hasNotification) {
        $.message("show", {
            text: data.message,
            type: data.type
        });
        if (Object.prototype.hasOwnProperty.call(data, "partial")) {
            loadPartial(config, data.partial);
        }
        docReady();
        if (!data.hasModalErrors) clearValidation();
        return;
    }
    if (data.reloadPage) {
        reloadPage();
        return;
    }
    if (data.closeModal) {
        closeModal();
        if (!data.hasModalErrors) clearValidation();
        return;
    }
    if (data.redirect) {
        redirect(data.redirect);
        return;
    }
    if (data.hasModelErrors) {
        loadPartial(config.modelError, data.partial);
        docReady();
        return;
    }
    else clearValidation();

    if (config.dismiss) {
        closeModal();
    }
    if (data.showModal) {
        $("#dialog-content").html(data.modal);
        $("#dialog-content").on("shown.bs.modal", function () {
            docReady();
        });
        if (Object.prototype.hasOwnProperty.call(data, "partial")) {
            loadPartial(config, data.partial);
        }
        if (!data.hasModalErrors) clearValidation();
        $("#dialog-content").modal("show");
        return;
    }

    const partial = Object.prototype.hasOwnProperty.call(data, "partial") ? data.partial : data;
    loadPartial(config, partial);
    docReady();
}