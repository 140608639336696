import { showLoading, stopLoading } from "../loadingScreen";
import { onError } from "../onError";
import { uniqueSelector } from "./uniqueSelector";

$(function(){
  $(document).on("change", ".ajax-select", ajaxSelectChange);
})

/**
 * Handler for @Ajax.select value change (_LienholderSelect.cshtml)
 * @param {JQuery.Event} e
 */
function ajaxSelectChange(e) {
  e.preventDefault();
  let $this = $(this);
  let url = $this.data("url");
  let id = $this.val();
  let loading = showLoading();
  $.ajax({
    url,
    data: { id },
    source: uniqueSelector($this, ".ajax-select")
  }).fail(function (data) {
    onError(data);
  }).always(function () {
    stopLoading(loading);
  });
}