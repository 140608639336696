let stopLoad = new Map();

export function showLoading(settings) {
    let options = {
        target: document.body,
        instant: false
    };
    $.extend(options, settings)

    let loadingElement = document.getElementById('loading');
    if (loadingElement) {
        var loadingNode = loadingElement.childNodes[1].cloneNode(true);
        let delayMilliseconds = 250;
        if (options.instant) {
            delayMilliseconds = 1;
        }
        if (!options.target) {
            options.target = document.body;
        }
        if (options.target) {
            setTimeout(() => {
                if (!stopLoad.get(loadingNode)) {
                    if (options.target.childNodes.length != 0) {
                        options.target.insertBefore(loadingNode, options.target.childNodes[1]);
                    }
                    else {
                        options.target.appendChild(loadingNode);
                    }
                }
            }, delayMilliseconds);
        }
        stopLoad.set(loadingNode, false);
        return loadingNode;
    }
    return null;
}

export function stopLoading(loadingNode) {
    stopLoad.set(loadingNode, true);
    if(loadingNode)
        loadingNode.remove();
}