import { serverValidator } from "./serverValidator";

/**
 * Included with the docReady function.
 * Applied to every form.client-validation element.
 */
export const clientValidationConfig = {
  messages: {
      available: function ($input) {
          const inputId = $input.attr("id");
          const inputCache = serverValidator.cache(inputId); console.log(inputCache);
          const message = $input.data("available-msg");
          if ($input.val() === inputCache.value) {
              return message;
          }
      }
  },
  rules: {
      email: function ($input) {
          if (/email/i.test($input.attr("type")))
              // eslint-disable-next-line no-control-regex
              return /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test($input.val());
              //return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i.test($input.val())
          else return true;
      },
      tel: function ($input) {
          let validate = $input.data("tel") || /tel/i.test($input.attr("type"));
          if (validate) {
              return $input.val().match(/\d/g).length >= 10;
          }
          return true;
      },
      available: function ($input) {
          let validate = $input.data("available");
          if (typeof validate !== "undefined" && validate !== false) {

              let inputId = $input.attr("id");
              if (!inputId) {
                  console.warn("Form inputs with availability check must have an id.");
                  return true;
              }
              if ($input.val() === $input.data("available-init")) return true;
              const inputCache = serverValidator.cache(inputId);

              let url = $input.data("available-url");
              if (url) {
                  let validator = $input.closest("form").data("kendoValidator");
                  let settings = { url, validator };

                  if (inputCache.value === $input.val()) {
                      inputCache.checking = false;
                      if (inputCache.valid) return true;
                      else return false;
                  }
                  else {
                      if (!inputCache.checking) {
                          inputCache.checking = true;
                          inputCache.valid = false;
                          serverValidator.check($input, settings);
                          return true;
                      }
                  }
                  return false;
              }
              else console.warn("A data-available-url attribute is requried.");
              return true;
          }
          return true;
      }
  }
}