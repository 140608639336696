/**
 * Provides a selector to uniquely identify the provided element.
 * @param {JQuery} $elem The element you need to uniquely identify
 * @param {string?} selector The selector you've got
 * @returns {string} A selector that is currently unique
 */
export function uniqueSelector($elem, selector) {
  if (!$elem) {
      return console.warn("No element was provided.");
  }
  const idAttr = "uid";
  let testSelector;
  let $testElem;

  if (selector) {
      testSelector = selector;
      $testElem = $(testSelector);
      if ($testElem.length == 1 && $testElem.get(0) === $elem.get(0)) return testSelector;
  }

  if ($elem.attr("id")) {
      testSelector = `#${$elem.attr("id")}`;
      $testElem = $(testSelector);
      if ($testElem.length == 1 && $testElem.get(0) === $elem.get(0)) return testSelector;
  }

  if ($elem.data(idAttr)) {
      testSelector = `[data-${idAttr}='${$elem.data(idAttr)}']`;
      $testElem = $(testSelector);
      if ($testElem.length == 1 && $testElem.get(0) === $elem.get(0)) return testSelector;
  }

  let uniqueId = randomString();
  while ($(`[data-${idAttr}='${uniqueId}']`).length > 0) { uniqueId = randomString(); }
  $elem.attr(`data-${idAttr}`, uniqueId);
  return `[data-${idAttr}='${uniqueId}']`;
}

/** Generates a short random string. */
export function randomString() { return (Math.random() + 1).toString(36).substring(7); }