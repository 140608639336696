import { clientValidationConfig } from './kendo/index';
import { toggleRemit } from './toggleRemit';
import { popupMessage } from './popupMessage';


$(function () {
    docReady();
});

/**
 * Initializes common controls (data-toggle, data-table, form, etc.).
 */
export function docReady() {
  $("[data-toggle='tooltip']").tooltip();
  $(".data-table").each(function (index, elem) {
      if (!$.fn.dataTable.isDataTable(elem)) {            
          let $elem = $(elem);
          let culture = $("body").data("culture");
          let cfg = {
              order: [],
              stateSave: true,
              columnDefs: [
                  { targets: 'no-sort', orderable: false },
                  { targets: 'date-sort', type: 'date' },
                  { targets: 'number-sort', type: 'num' }
              ],
          };
          if (culture == "fr-CA") {
              $.extend(cfg, {
                  language: {
                      url: '//cdn.datatables.net/plug-ins/1.11.4/i18n/fr_fr.json'
                  }
              });
          }
          if ($elem.hasClass("no-page")) cfg.paging = false;
          if ($elem.hasClass("no-search")) cfg.searching = false;
          if ($elem.hasClass("no-info")) cfg.info = false;
          $(elem).DataTable(cfg);
      }
  });

  $("form")
      .find("input.input-validation-error")
      .closest(".k-widget")
      .addClass("k-invalid");

  $("form")
      .find(".focus")
      .focus();

  $("form.client-validation").kendoValidator(clientValidationConfig);

  // hack to fix "k-numeric-wrap" input validation visual that does not display red border
  let $errorInput = $(".input-validation-error");
  $errorInput.closest(".k-numeric-wrap").addClass("input-validation-error");
  $errorInput.closest(".k-input").addClass("input-validation-error");

    toggleRemit();
    popupMessage();
}