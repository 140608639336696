﻿import { showMessageDialog } from './showMessageDialog';

/**
 * looks for dynamic messages on a popup-message class and calls showMessageDialog if the text exists
 * Used by docReady.
 */
export function popupMessage() {
    let element = $(".popup-message");
    let text = element.data("message-text");
    let clear = element.data("message-clear");
    if (element && text && text.length > 0) {
        showMessageDialog(text, clear);
    }
}


$(function () {
    $(document).on("hidden.bs.modal", "#modal-message", function () {
        let body = $("#modal-message .modal-body");
        if (body.hasClass("clear-message")) {
            $(".popup-message").attr("data-message-text", "");
            body.removeClass("clear-message");
        }
    });
});