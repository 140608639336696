import { showLoading, stopLoading } from "../loadingScreen";
import { onError } from "../onError";
import { getAntiforgeryToken } from "../getAntiforgeryToken";


$(function() {
  $(document).on("change", ".ajax-cascade", ajaxCascadeChange);
});


/**
 * Event handler for the @Ajax.cascade change event (UserManager/Index.cshtml)
 * @param {JQuery.Event} e
 */
function ajaxCascadeChange() {
  let $select = $(this);
  let $target = $($select.data("target"));
  let loading = showLoading();
  let url = $select.data("url");
  let formData = new FormData();
  formData.append($select.attr("name"), $select.val());
  formData.append("__RequestVerificationToken", getAntiforgeryToken());
  $.ajax({
    method: "post",
    cache: false,
    processData: false,
    contentType: false,
    url: url,
    data: formData
  }).done(function (data) {
    let options = ``;
    for (let i = 0; i < data.length; i++) {
        options += `<option value="${data[i].value}">${data[i].text}</option>`;
    }
    $target.find("option").not(':first').remove();
    $target.append(options);
    $target.change();
  }).fail(function (data) {
    onError(data);
  }).always(function () {
    stopLoading(loading);
  });
}