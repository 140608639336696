import { docReady } from "../docReady";
import { onError } from "../onError";


$(function() {
  $(document).on("change", ".remit-switch", remitSwitch);
});


/**
 * Updates a cookie when the user clicks any remit switch.
 */
export function remitSwitch() {
    let $remit = $(this);
    let url = $remit.data("action");
    if (url && url.length > 0) {
        $.ajax({
            url: url
        }).done(function () {
            docReady();
        }).fail(function (data) {
            onError(data);
        });
    }
}