(function ($) {
  $.message = function (action, message) {
      let notification = $("#notification").data("kendoNotification");
      
      if (action === "show") {
          let hide = message.duration;
          if (!hide && hide !== 0) {
              hide = $("#notification").data("auto-hide");
          }
          notification.setOptions({
              autoHideAfter: hide
          });
          notification.show({
              title: message.title,
              message: message.text
          }, message.type);
      }

      if (notification && action === "clear") {
          notification.hide();
      }
      return notification;
  };
}(jQuery));