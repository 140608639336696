
export function localizeCurrency(amount) {
  let culture = $("body").data("culture") ?? "en-US";
  let localized = amount.toLocaleString(culture, {
      style: "currency",
      currency: (culture === "en-US") ? "USD" : "CAD",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 2
  });
  return localized;
}