import { showLoading } from "../loadingScreen";
import { getAntiforgeryToken } from "../getAntiforgeryToken";
import { reloadPage } from "../reloadRedirect";
import { redirect } from "../reloadRedirect";
import { docReady } from "../docReady";
import { onError } from "../onError";
import { stopLoading } from "../loadingScreen";

$(function() {
  $(document).on("click", ".ajax-dialog", ajaxDialogClick);
});

/**
 * Performs an ajax call then shows a dialog.
 * @param {JQuery.Event} e
 */
export function ajaxDialogClick(e) {
  e.preventDefault();
  let $this = $(this);
  let url = $this.attr("href");
  if (!url || url === 'undefined') {
    url = $this.data("url");
  }
  let method = "POST";
  if ($this.data("method")) method = $this.data("method");
  let loading = showLoading();
  let antiforgerytoken = getAntiforgeryToken();
  $.ajax({
    method,
    url: url,
    data: { __RequestVerificationToken: antiforgerytoken }
  }).done(function (data) {
    if (data.reloadPage) {
      reloadPage();
    }
    else if (data.redirect) {
      redirect(data.redirect);
    }
    else {
      let targetDialog = $this.data("target-dialog");
      if (!targetDialog) targetDialog = "#dialog-content";
      let $dialog = $(targetDialog);
      $dialog.html(data);
      $dialog.one("shown.bs.modal", function () {
        docReady();
      });
      $dialog.modal("show");
    }
  }).fail(function (data) {
    onError(data);
  }).always(function () {
    stopLoading(loading);
  });
}