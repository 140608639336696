﻿/**
 * Dispatches custom events from the document.
 * detail parameters should be objects (ie. { dealerId: 4 })
 */
export const dispatch = {
    /** Fires when the Caps Lock state change is detected. */
    capsLock: detail => { dispatchEvent("CapsLock", detail); },
    dealerSelected: detail => { dispatchEvent("DealerSelected", detail); },
    partialLoad: detail => { dispatchEvent("PartialLoad", detail); }
}

function dispatchEvent(name, eventData) {
    if (eventData) document.dispatchEvent(new CustomEvent(name, { detail: eventData }));
    else document.dispatchEvent(new CustomEvent(name));
}