
/**
 * Gets the culture neutral value, or zero if no value is available.
 * @param {JQuery} $input
 * @returns {number} 
 */
const get = $input => {
    let val = parseFloat($input.data("invariant"));
    if (!isNaN(val)) return val;
    return 0;
}
/**
 * Sets the culture specific and invariant value.
 * @param {JQuery} $input
 * @param {number|undefined} val
 */
const set = ($input, val) => {
    if (typeof (val) === 'number') $input.val(val.toLocaleString(culture)).data("invariant", val);
    else $input.val('').data("invariant", '');
}
/**
 * @param {JQuery<HTMLElement>[]} $collection
 */
const collection = $collection => ({
    /**
     * Gets the culture neutral value, or zero if no value is available or the element is not found.
     * @param {string} selector
     * @returns {number}
     */
    get: selector => get($collection.find(selector)),
    /**
     * Sets the culture specific and invariant value of an element if it is found in the collection.
     * @param {string} selector
     * @param {number} val
     */
    set: (selector, val) => set($collection.find(selector), val),
    /**
     * Queries the collection for the proided selector.
     * @param {any} selector
     * @returns {JQuery}
     */
    find: selector => $collection.find(selector)
});

/**
 * Support functions for getting and setting values
 * for input elements that have both culture and
 * culture neutral values.
 */
export { get, set, collection }