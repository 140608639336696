﻿import { dispatch } from '../dispatch';

/**
 * Global site.capsLock variable - True when Caps Lock is on.
 * Syncs with the first key press.
 */
export let capsLock = false;

/*
    You can use the caps-lock-warning class to show/hide block 
    display elements that show the status of the Caps Lock key.
 */
function showCapsLockWarnings() {
    if (capsLock) $(".caps-lock-warning").addClass("caps-lock-on");
    else $(".caps-lock-warning").removeClass("caps-lock-on");
}

$(function () {
    showCapsLockWarnings();

    $(document).on("keydown", function (e) {
        capsLock = e.originalEvent.getModifierState && e.originalEvent.getModifierState('CapsLock');
        showCapsLockWarnings();
        dispatch.capsLock({ capsLock });
    });
});