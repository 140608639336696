﻿$(function () {
    if ($("#login-form").get(0)) {
        let username = localStorage.getItem("username");
        if (username) {
            $("#Username").val(username);
            $("#RememberMe").prop("checked", true);
            $("#Password").focus();
        }
        $(document).on("submit", "#login-form", function () {
            const $rememberMe = $("#RememberMe");
            if ($rememberMe.prop("checked")) {
                localStorage.setItem("username", $("#Username").val());
            }
            else {
                localStorage.removeItem("username");
            }
        });
    }
});