import { reloadPage, redirect } from "./reloadRedirect";

/**
 * Error handling for ajax requests
 * @param {Object} data - Required
 */
export function onError(data) {
  // unauthorized for the Ajax request. Reload the current page to 
  // force Authorization middleware to redirect to the login page.
  if (data.status === 401 || data.status === 403) {
    reloadPage();
    return false;
  }

  if (data.status === 404) {
    redirect("/error/404");
    return false;
  }        

  let title = $("#default-error").data("title");
  let message = $("#default-error").data("text");
  let serverMessage = null;
  if (data.status === 500) {
    serverMessage = data.responseText;
    title = title ?? "Error";
    message = message ?? "An error occurred processing your last request.";
  }
  if (Environment.isDevelopment) {
    let msg = data.statusText + " " + data.status;
    if (serverMessage != null) {
      msg = serverMessage.replace(/(^"|"$)/g, '') // remove beginning and ending double quotes
      msg = msg.replace(/(?:\r\n|\\r\\n|\r|\n)/g, '<br />');
    }
    document.write('<div>' + msg + '</div>');
    return false;
  }

  $.message("show", {
    title,
    text: message,
    type: "error"
  });
}