// Use Telephone class to move the cursor to the last digit on focus
// This has only been tested in Telerik controls
$(document).on("click", ".Telephone", function (e) {
  e.preventDefault();
  e.stopPropagation();
  let tel = $(this).val();
  let m = tel.match(/\d/g);
  let pos = 1;
  if (m != null) {
      pos += m.length;
      if (pos > 3) pos += 2;
      if (pos > 8) pos++;
  }
  e.target.setSelectionRange(pos, pos);
});