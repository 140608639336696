﻿/**
 * Not intended for use with Telerik
 */
$(document).on("keydown", ".tel", function (e) {
    if (!$(this).data('num')) $(this).data('num', '');
    if (
        ((e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode >= 96 && e.keyCode <= 105)) &&
        ($(this).data('num').length > 9)
    ) return false;
});
$(document).on("keyup", ".tel", function (e) {
    let $this = $(this);
    let num = $this.val().replace(/\D+/g, '');
    $this.data('num', num);
    if (e.keyCode !== 8) $this.val(formatPhoneNumber(num));
});

function formatPhoneNumber(num) {
    if (!num) return;
    let fmt = '';
    fmt = num.length < 3 ? num :
        num.length < 6 ? `(${num.substr(0, 3)}) ${num.substr(3)}` :
            `(${num.substr(0, 3)}) ${num.substr(3, 3)}-${num.substr(6)}`;
    return fmt;
}