﻿/**
 * Logs some details about all event handlers connected to the document element.
 */
export function showEventListeners() {
    let e = jQuery._data(document, "events");
    let r = [];
    for (let prop in e) {
        for (let i = 0; i < e[prop].length; i++) {
            r.push({
                type: e[prop][i].type,
                selector: e[prop][i].selector,
                data: e[prop][i]
            });
        }
    }
    console.log(r);
}