$(function() {
    $(document).on("focus", "[data-role='datepicker']:not(.suppress-auto-open)", autoOpen);
    $(document).on("blur", "input[data-role='datepicker'], .keyed-date", parseKeyedDate);
});

function autoOpen() {
  let datePicker = $(this).data("kendoDatePicker");
  if (datePicker && datePicker.open) datePicker.open();
}

function parseKeyedDate(e) {
    e.preventDefault();
    let $this = $(this);
    let val = $this.val();
    let datePicker = $this.data("kendoDatePicker");
    if (datePicker) {
        if (val && !datePicker.value() && val.length == 8 && !isNaN(val)) {
            if (/CA$/.test(culture))
                datePicker.value(`${val.substring(0, 4)}-${val[4]}${val[5]}-${val.substring(6)}`);
            else
                datePicker.value(`${val[0]}${val[1]}/${val[2]}${val[3]}/${val.substring(4)}`);
            datePicker.trigger("change");
        }
    }
    else {
        if (val && val.length == 8 && !isNaN(val)) {
            if (/CA$/.test(culture))
                $this.val(`${val.substring(0, 4)}-${val[4]}${val[5]}-${val.substring(6)}`);
            else
                $this.val(`${val[0]}${val[1]}/${val[2]}${val[3]}/${val.substring(4)}`);
        }
    }
}