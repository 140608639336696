/**
 * Provides the antiforgery token if available, otherwise null.
 * @returns {string|null} a value for use as __RequestVerificationToken
 * @example __RequestVerificationToken: getAntiforgeryToken()
 */
export function getAntiforgeryToken() {
  var input = document.querySelector('input[name="__RequestVerificationToken"]');
  if (input) {
      let antiforgerytoken = input.value;
      return antiforgerytoken;
  }
  return null;
}