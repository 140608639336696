﻿/*
    Toggle switch to alter between a password or text input.

    pw-container
        pw-toggle with initial state (pw-shown or pw-hidden)
            pw-show     element shown when in pw-hidden state
            pw-hide     element shown when in pw-shiown state
    
    The toggle buttons are positioned to the right of the input in site.scss

    Example:
    --------
    <div class="pw-container">
        <input type="password" />
        <span class="pw-toggle pw-hidden">
            <i class="fa fa-eye pw-show"></i>
            <i class="fa fa-eye-slash pw-hide"></i>
        </span>
    </div>
 */

$(function () {
    $(document).on("click", ".pw-container .pw-toggle", function () {
        let $this = $(this);
        if ($this.hasClass("pw-shown")) {
            $this.removeClass("pw-shown").addClass("pw-hidden");
            $this.closest(".pw-container").find("input").attr("type", "password");
        }
        else {
            $this.removeClass("pw-hidden").addClass("pw-shown");
            $this.closest(".pw-container").find("input").attr("type", "text");
        }
    });
});