﻿/**
 * shows a message in a popup, bootstrap modal dialog 
 * Used by popupMessage.
 */
export function showMessageDialog(message, clear) {
    let body = $("#modal-message .modal-body");
    body.text(message);
    if (clear) {
        body.addClass("clear-message");
    }
    $("#modal-message").modal("show");
}
