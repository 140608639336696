import { showLoading, stopLoading } from "../loadingScreen";
import { onError } from "../onError";

$(document).on("click", ".ajax-action", function (e) {
    e.preventDefault();
    doAjaxAction($(this));
});

/**
 * Invokes common ajax request/response handling on the provided element.
 * @param {JQuery} source Element with common ajax data attributes for handling.
 */
export function doAjaxAction(source) {
    let loading = showLoading();
    let url = source.attr("href");
    if (url === 'undefined' || url === undefined) {
        url = source.data("url");
    }

    $.ajax({
        url,
        source
    }).fail(function (data) {
        onError(data);
    }).always(function () {
        if (loading) stopLoading(loading);
    });
}