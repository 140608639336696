/**
 * Creates a plain JavaScript object from a form selector or JQuery form.
 * @param {JQuery|string} jqFormOrSelector
 * @returns {object}
 */
export function serializeFormToObject(jqFormOrSelector) {
  const mapField = (obj, formProp) => {
      let i = formProp.name.indexOf('.');
      if (i == -1) {
          obj[formProp.name] = formProp.value;
          return obj;
      }

      let propName = formProp.name.substring(0, i);
      let nextProp = Object.assign({}, formProp);
      nextProp.name = formProp.name.substring(i + 1);

      let indexMatch = propName.match(/\[(\d+)\]/);
      if (indexMatch) {
          let indexStr = indexMatch[1];
          if (isNaN(indexStr)) return console.warn(`${propName} - ${indexStr} could not be parsed as a number.`);
          let currentIndex = parseInt(indexStr);
          propName = propName.substring(0, indexMatch.index);
          if (!obj[propName]) obj[propName] = [];
          if (obj[propName].length > currentIndex) mapField(obj[propName][currentIndex], nextProp);
          else obj[propName].push(mapField({}, nextProp));
      }
      else {
          if (obj[propName]) mapField(obj[propName], nextProp);
          else obj[propName] = mapField({}, nextProp);
      } 
      return obj;
  }

  if (typeof (jqFormOrSelector) === "string") return $(jqFormOrSelector).serializeArray().reduce((obj, formProp) => mapField(obj, formProp), {});
  if (jqFormOrSelector instanceof jQuery) return jqFormOrSelector.serializeArray().reduce((obj, formProp) => mapField(obj, formProp), {});
}