﻿/**
 * Click handler for the Universal Lenders Zero Plan button.
 */
$(function () {
    $(document).on("click", "#universal-lenders", function (e) {
        e.preventDefault();
        let $this = $(this);
        let url = $this.data("url");
        let data = { __RequestVerificationToken: site.getAntiforgeryToken() };
        if ($this.data("esc")) data.escContractId = $this.data("esc");
        if ($this.data("tw")) data.twContractId = $this.data("tw");
        let loading = site.showLoading();
        $.ajax({
            method: "POST",
            url,
            data
        }).done(function (data) {
            if (data) window.open(data, "_blank");
            $("#universal-lenders").remove();
        }).fail(function (error) {
            site.onError(error);
        }).always(() => {
            site.stopLoading(loading);
        });
    });
});