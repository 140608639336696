/**
 * Shows/hides remit values (dealer cost) in all sections
 * of the current page based on the position of the remit
 * switch in the section.
 * Used by docReady.
 */
export function toggleRemit() {  
  $('.remit-switch').each(function() {
      let $remit = $(this);
      let $container = $remit.closest(".remit-container");
      if ($remit.is(":checked")) {
          $container
              .find(".remit")
              .removeClass("remit-none");
      }
      else {
          $container
              .find(".remit")
              .addClass("remit-none");
      }
  });
}