import { showLoading, stopLoading } from "../loadingScreen";
import { onError } from "../onError";

$(function() {
    $(document).on("submit", ".ajax-form", ajaxFormSubmit);
});

/**
 * Handler for the @Ajax.form submit event.
 * @param {JQuery.Event} e
 */
function ajaxFormSubmit(e) {
  e.preventDefault();

  let form = $(this);
  let formButton = form.find(".form-button");
  let formButtonText = null;
  if (formButton) {
      formButtonText = formButton.val();
      let processingText = formButton.data("processing");
      formButton.val(processingText);
      formButton.prop("disabled", true);
  }

  let method = form.attr("method");
  let url = form.attr("action");
  let loading = showLoading();
  let skipStopLoad = false;

  form.find("input:not(.form-button), select").each(function () {
      let $this = $(this);
      $this.prop("disabled", false)
  });
  let postData = form.serialize();

  $.ajax({
      method: method,
      url: url,
      cache: false,
      data: postData,
      source: form //".ajax-form"
  }).fail(function (data) {
      onError(data);
  }).always(function () {
      if (!skipStopLoad)
          stopLoading(loading);

      if (formButton) {
          formButton.val(formButtonText)
          formButton.prop("disabled", false);
      }
  });
}