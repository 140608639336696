import { getAntiforgeryToken } from '../getAntiforgeryToken'

export const serverValidator = function() {
  const _cache = {};
  const cache = id => {
    if (!_cache[id]) _cache[id] = {};
    return _cache[id];
  }
  return {
    cache,
    check: function ($input, settings) {
      const inputId = $input.attr("id");
      if (!inputId) {
        console.error("Input must have an id.");
        return true;
      }
      const inputCache = cache(inputId);

      $.ajax({
        method: 'POST',
        url: settings.url,
        data: {
            [$input.attr("name")]: $input.val(),
            __RequestVerificationToken: getAntiforgeryToken()
        },
        cache: false
      }).done(function (data) {
        inputCache.valid = data.isAvailable;
      }).fail(function () {
        inputCache.valid = true;
      }).always(function () {
        inputCache.value = $input.val();
        settings.validator.validateInput($input);
      });
    }
  }
}();