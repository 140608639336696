/**
 * Generates a serialized string from a form selector or JQuery form.
 * @param {JQuery|string} jqFormOrSelector
 * @returns {string}
 */
 export function serializeForm(jqFormOrSelector) {
  const serializeInput = (serialStr, elem) => {
    const $elem = $(elem);
    const masked = $elem.data("kendoMaskedTextBox");
    const serialized = $elem.serialize();
    const amp = serialStr.length > 0 ? '&' : '';

    return masked ? serialStr + amp + $elem.attr("name") + "=" + masked.raw() :
        serialized ? serialStr + amp + serialized :
            serialStr;
  }
  if (typeof (jqFormOrSelector) === "string") return $(jqFormOrSelector).find(":input").toArray().reduce(serializeInput, "");
  if (jqFormOrSelector instanceof jQuery) return jqFormOrSelector.find(":input").toArray().reduce(serializeInput, "");
}