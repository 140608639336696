export function clearValidation() {
  $("form")
      .find(".input-validation-error")
      .removeClass("input-validation-error");

  $("form")
      .find(".field-validation-error")
      .removeClass("field-validation-error")
      .addClass("field-validation-valid")
      .html("");

  $("form")
      .find(".k-widget")
      .removeClass("k-invalid")
}