﻿/* 
    Attaches event handlers to #DealerId "change".
    
    The script fires a custom "DealerSelected" event on the document when a dealer is selected.
    Subscribe with $(document).on("DealerSelected", function(e) { ... });
    The dealer id is provided in event.detail.dealerId

    Respond to the start of the animation with one of the following:
    $(document).on("show.bs.collapse", "#dealerAddress", function() {...});
    $(document).on("hide.bs.collapse", "#dealerAddress", function() {...});

    Also respond to the completed animation with one of the following:
    $(document).on("shown.bs.collapse", "#dealerAddress", function() {...});
    $(document).on("hidden.bs.collapse", "#dealerAddress", function() {...});

    dealerAddressElem is defined in Shared/Components/DealerOptionsPanel/Default.cshtml
*/
/* global dealerAddressElem */
$(function () {
    let dealerSelect = document.querySelector("select#DealerId.dealer-options-panel");
    if (dealerSelect) {
        $(document).on("change", "#DealerId", function () {
            let dealerId = $("#DealerId").val();
            let antiforgeryTokenInput = document.querySelector('input[name="__RequestVerificationToken"]');
            $.ajax({
                method: "POST",
                url: "/Portal/Rating/GetDealer",
                data: {
                    dealerId,
                    __RequestVerificationToken: antiforgeryTokenInput ? antiforgeryTokenInput.value : null
                }
            }).done(function (data) {
                if (typeof (dealerAddressElem) !== 'undefined') {
                    if (data == null) dealerAddressElem.hide();
                    else {
                        $("#dealerAddress").html(data);
                        dealerAddressElem.show();
                    }
                }
                site.dispatch.dealerSelected({ dealerId });
            }).fail(function (data) {
                site.onError(data);
            });
        });
    }
});